
'use client'

import Link from "next/link";
import { useEffect, useState } from "react";
import MainMenu from "@/components/header/MainMenu";
import CurrenctyMegaMenu from "@/components/header/CurrenctyMegaMenu";
import LanguageMegaMenu from "@/components/header/LanguageMegaMenu";

import MobileMenu from "@/components/header/MobileMenu";
import LOGO_LIGHT from "@/img/general/logo-light.svg";
import LOGO_DARK from "@/img/general/logo-dark.svg";
import Image from "next/image"
import useAuthModal from "@/hooks/useAuthModal";
import AuthModal from "@/components/common/AuthModal";
import { useAppDispatch } from "@/lib/hooks";
import { useSession } from "next-auth/react";
import { logout } from "@/lib/features/profile/profileSlice";
import { usePathname } from "next/navigation";


const Header1 = () => {
  const currentUrl = usePathname();
  const { isModalOpen, openModal, closeModal } = useAuthModal();
  const [navbar, setNavbar] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { data: session, status } = useSession();
  
  const changeBackground = () => {
    if (typeof window !== "undefined" && window.scrollY >= 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", changeBackground);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", changeBackground);
      }
    };
  }, []);

  return (
    <>
     <AuthModal isOpen={isModalOpen} toggle={closeModal} />
      <header className={`header bg-white ${navbar ? "is-sticky" : ""}`}>
        <div className="header__container px-30 sm:px-20">
          <div className="row justify-between items-center">
            <div className="col-auto">
              <div className="d-flex items-center">
                <Link href="/" className="header-logo mr-20">
                  <Image src={LOGO_LIGHT} alt="logo icon"  width={80} height={80}  />
                  <Image src={LOGO_DARK} alt="logo icon" width={80} height={80} />
                </Link>
                {/* End logo */}

                {/* <div className="header-menu">
                  <div className="header-menu__content">
                    <MainMenu style="text-dark-1" />
                  </div>
                </div> */}
                {/* End header-menu */}
              </div>
              {/* End d-flex */}
            </div>
            {/* End col */}

            <div className="col-auto">
              <div className="d-flex items-center">
                <div className="row x-gap-20 items-center xxl:d-none">
                  <CurrenctyMegaMenu textClass="text-dark-1" />
                  {/* End Megamenu for Currencty */}

                  {/* Start vertical devider*/}
                  <div className="col-auto">
                    <div className="w-1 h-20 bg-white-20" />
                  </div>
                  {/* End vertical devider*/}

                  {/* <LanguageMegaMenu textClass="text-dark-1" /> */}
                  {/* End Megamenu for Language */}
                </div>
                {/* End language and currency selector */}

                {/* Start btn-group */}
                <div className="d-flex items-center ml-20 is-menu-opened-hide md:d-none">
                  {/* <Link
                    href="/login"
                    className="button px-30 fw-400 text-14 -blue-1 bg-blue-1 h-50 text-white"
                  >
                    Become An Expert
                  </Link> */}
                  
                   
                  {status === "unauthenticated" ? (
                    <>
                    { (currentUrl != "/login" && currentUrl != "/signup") && <div
                    onClick={openModal}
                      className="button px-30 fw-400 text-14 -outline-blue-1 h-50 text-blue-1 ml-20 onHover"
                    >
                      Sign In / Register
                    </div>}
                    </>
                  ) : (
<>
                    <Link
                      href={`/dashboard/db-booking`}
                      className="button px-30 fw-400 text-14 -outline-blue-1 h-50 text-blue-1 ml-20 onHover"
                    >
                      Dashboard
                    </Link>

                    <button
                      onClick={() =>
                        dispatch(logout({ url: "v1/user/logout" }))
                      }
                      className="button px-30 fw-400 text-14 -outline-blue-1 h-50 text-blue-1 ml-20 onHover"
                    >
                      Logout
                    </button>
                    </>
                  )}


                  {/* <Link
                    href="/signup"
                    className="button px-30 fw-400 text-14 -outline-blue-1 h-50 text-blue-1 ml-20"
                  >
                    Sign In / Register
                  </Link> */}
                </div>
                {/* End btn-group */}

                {/* Start mobile menu icon */}
                <div className="d-none xl:d-flex x-gap-20 items-center pl-30 text-dark-1">
                  <div>
                    <Link
                      href="/login"
                      className="d-flex items-center icon-user text-inherit text-22"
                    />
                  </div>
                  <div>
                    <button
                      className="d-flex items-center icon-menu text-inherit text-20"
                      data-bs-toggle="offcanvas"
                      aria-controls="mobile-sidebar_menu"
                      data-bs-target="#mobile-sidebar_menu"
                    />

                    <div
                      className="offcanvas offcanvas-start  mobile_menu-contnet"
                      tabIndex={-1}
                      id="mobile-sidebar_menu"
                      aria-labelledby="offcanvasMenuLabel"
                      data-bs-scroll="true"
                    >
                      <MobileMenu />
                      {/* End MobileMenu */}
                    </div>
                  </div>
                </div>
                {/* End mobile menu icon */}
              </div>
            </div>
            {/* End col-auto */}
          </div>
          {/* End .row */}
        </div>
        {/* End header_container */}
      </header>
    </>
  );
};

export default Header1;
